import {DIAware} from "ui-base/src/_resources/ts/DI/DIAware";
import {DI} from "ui-base/src/_resources/ts/DI/DI";

export abstract class AbstractHandler extends DIAware
{
    constructor()
    {
        super();
        this.setDI(new DI());
    }

    static shouldRun(): boolean
    {
        return true;
    }

    run(): void {}
}
