import {AbstractHandler} from "ui-base/src/_resources/ts/Handlers/AbstractHandler";
import {DI} from "../DI/DI";

declare const $: any;

export class BrowserNavigatorInfoCookieHandler extends AbstractHandler {

  private cookieValue: object = {};

  static shouldRun(): boolean
  {
    let di = new DI();
    let cookieModule = di.getCookieModule();
    let navigatorCookieSet = cookieModule.getCookie('navigator');
    let isSafari = di.getUserAgentModule().isSafari();

    return !navigatorCookieSet && isSafari;
  }

  run(): any
  {
    this
      .addPlatformInfo()
      .addMaxTouchPointsInfo()
      .setNavigatorCookie();

    window.location.reload();
  }

  private addPlatformInfo(): this
  {
    this.cookieValue["platform"] = navigator.platform;
    return this;
  }

  private addMaxTouchPointsInfo(): this
  {
    this.cookieValue["maxTouchPoints"] = navigator.maxTouchPoints;
    return this;
  }

  private setNavigatorCookie(): this
  {
    let di = this.getDI();
    let cookieModule = di.getCookieModule();

    cookieModule
      .setCookie(
        'navigator',
        JSON.stringify(this.cookieValue),
        "." + di.getEnvironmentModule().getDomain()
      );

    return this;
  }
}
