import {HashHandler} from "./Handlers/HashHandler";
import {DI} from "./DI/DI";
import {AbstractHandler} from "ui-base/src/_resources/ts/Handlers/AbstractHandler";
import {BrowserNavigatorInfoCookieHandler} from "ui-base/src/_resources/ts/Handlers/BrowserNavigatorInfoCookieHandler";
import {ForceHrefHandler} from "ui-base/src/_resources/ts/Handlers/ForceHrefHandler";

declare let $: any;
export class UiBaseMainApp
{
  protected _initialisedComponents: Object = {};
  protected _componentsInitialised: boolean = false;

  public constructor()
  {
    this.loadHandlers();
    this.loadComponents();
  }

  private loadHandlers()
  {
    for (let handler of this.getHandlers())
    {
      try
      {
        if (handler.shouldRun())
        {
          let instantiatedHandler: AbstractHandler = new handler();
          instantiatedHandler.run();
        }
      }
      catch (e)
      {
        console.log('handler need to extend AbstractHandler');
        new handler();
      }

    }
  }

  private loadComponents()
  {
    let curatedComponents = {};
    this._initialisedComponents = [];

    for (let component of this.getComponents())
    {
      curatedComponents[component.selector] = component;
    }

    for (let componentSelector in curatedComponents)
    {
      let component = curatedComponents[componentSelector];
      let elements = $('[' + componentSelector + ']');

      this.initialiseComponent(elements, componentSelector, component);
    }

    this._componentsInitialised = true;

    for(let key in this._initialisedComponents)
    {
      for(let index = 0; index < this._initialisedComponents[key].length; index++)
      {
        this._initialisedComponents[key][index].afterInit();
      }
    }

    return this;
  }

  public getInitialisedComponents(selector: string = null)
  {
    if(!this._componentsInitialised)
    {
      console.warn('Components have not yet been initialised when calling getInitialisedComponents(), try using in the afterInit() component method');
    }

    return this._componentsInitialised && selector ? this._initialisedComponents[selector] : this._initialisedComponents;
  }

  public updateComponents()
  {
    let curatedComponents = {};

    for (let component of this.getComponents())
    {
      curatedComponents[component.selector] = component;
    }

    for (let componentSelector in curatedComponents)
    {
      let component = curatedComponents[componentSelector];
      let elements = $('[' + componentSelector + ']');

      elements = elements.filter((index) => {
        return $(elements[index]).attr('component-id') === undefined;
      });

      this.initialiseComponent(elements, componentSelector, component);
    }

    return this;
  }

  private initialiseComponent(elements, componentSelector: string, component)
  {
    let initComponents = [];

    for (let key = 0; key < elements.length; key++)
    {
      if (elements.hasOwnProperty(key)) {
        let element = $(elements[key]);
        let id = componentSelector + '_' + key;
        element.attr('component-id', id);
        let di = new DI();
        let initComponent = (new component(element, di.setApp(this)));
        if (key === 0) initComponent.runOnce();
        initComponent.init();
        initComponents.push(initComponent);
      }
    }
    this._initialisedComponents[componentSelector] = initComponents;
  }

  protected getHandlers(): Array<any>
  {
    return [
      HashHandler,
      BrowserNavigatorInfoCookieHandler,
      ForceHrefHandler
    ];
  }

  protected getComponents(): Array<any>
  {
    return [];
  }

}
