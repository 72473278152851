import {UserAgentModule} from "ui-base/src/_resources/ts/DI/Modules/UserAgent/UserAgentModule";
import {AbstractHandler} from "ui-base/src/_resources/ts/Handlers/AbstractHandler";

declare let $: any;

export class HashHandler extends AbstractHandler
{
    private userAgentModule: UserAgentModule;

	run()
	{
	    this.userAgentModule = this.getDI().getUserAgentModule();

		$(document).ready(() => {
			if (window.location.hash) this.triggerCustomHashChangeEvents();

			// Listen for hash changes to the url example #something-here
			window.onhashchange = this.triggerCustomHashChangeEvents.bind(this);
		});
	}

	triggerCustomHashChangeEvents()
	{
		if (location.hash !== '#')
		{
			let hashes = location.hash.substring(1).split(":");

			if (hashes.length >= 1) {
				for (let hashKey in hashes) {
					$(document).trigger('custom-hash-change', [hashes[hashKey]]);
				}
			}

            // clear the hashstring if it matches a tab
            history.replaceState(null, null, window.location.href.split('#')[0]);

            if(!this.userAgentModule.isFirefox())
            {
                // Older browser Support
                window.location.hash = '';
            }
		}
	}
}



