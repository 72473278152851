import {UserAgentModule} from "ui-base/src/_resources/ts/DI/Modules/UserAgent/UserAgentModule";
import {AbstractHandler} from "ui-base/src/_resources/ts/Handlers/AbstractHandler";

declare let $: any;

export class ForceHrefHandler extends AbstractHandler
{
    private userAgentModule: UserAgentModule;

	run()
	{
		this.setupForceHrefListener();
	}

	private setupForceHrefListener(): boolean {
		let keydown = null;

		$(document)

			// Setup key event listeners to check for ctrl & cmd keys
			.on("keydown keypress", function (e) {
				keydown = e.which;
			})
			.on("keyup", function () {
				keydown = null;
			})

			// handle links with @href started with '#' only
			.on('click', '[force-href]', function (e) {
				e.stopPropagation();

				let element = $(e.currentTarget);
				let delayAttr = element.attr('force-delay');
				let delay: number = delayAttr === undefined ? 0 : parseInt(delayAttr);

				// get url from attribute
				let url = element.attr('force-href');
				let target = (element.attr('force-target')) ? element.attr('force-target') : '_self';

				setTimeout(() => {
					// Navigate url
					if (keydown == 91 || keydown == 17) {
						window.open(url, "_blank");
					} else {
						window.open(url, target);
					}
				}, delay);


			}.bind(this));

		return true;
	}
}



